.btn {
  /* transition: all 0.3s ease-out; */
  font-family: "Nunito Sans";
  font-weight: 600;
  letter-spacing: 0.03rem;
  outline: none;
  border-radius: 7px;
  cursor: auto;
}

.btn:enabled {
  cursor: pointer;
}

.btn--primary {
  background-color: var(--clr-secondary-blue-500);
  color: var(--clr-white);
  border: none;
}

.btn--secondary {
  background-color: var(--clr-white);
  color: var(--clr-gray-800);
  border: 0.1rem solid var(--clr-gray-300);
}

.btn--tertiary {
  background-color: transparent;
  color: var(--clr-gray-800);
  border: none;
}

.btn--error {
  background-color: var(--clr-mystic-red-500);
  color: var(--clr-white);
  border: none;
}

.btn--primary:hover:enabled {
  box-shadow: var(--box-shadow-up-hover);
  background: var(--clr-secondary-blue-700);
}

.btn--primary:focus:enabled {
  outline: 2px solid var(--clr-secondary-blue-200);
}

.btn--primary:active:enabled,
.btn--primary.pressed:enabled {
  background: var(--clr-secondary-blue-300);
}

.btn--primary:disabled {
  background: var(--clr-gray-200);
  color: var(--clr-gray-500);
}

.btn--secondary:hover:enabled {
  outline: 1px solid var(--clr-secondary-blue-400);
  box-shadow: var(--box-shadow-up-hover);
}

.btn--secondary:focus:enabled {
  outline: 3px solid var(--clr-secondary-blue-200);
}

.btn--secondary:active:enabled,
.btn--secondary.pressed:enabled {
  background: var(--clr-secondary-blue-100);
  outline: 1px solid var(--clr-secondary-blue-500);
}

.btn--secondary.isActive:enabled {
  border: 1px solid var(--clr-secondary-blue-200);
  background: var(--clr-secondary-blue-50);
  color: var(--clr-secondary-blue-500);
}

.btn--secondary:disabled {
  background: var(--clr-gray-50);
  color: var(--clr-gray-300);
}

.btn--tertiary:hover:enabled {
  outline: 0.1rem solid var(--clr-secondary-blue-200);
}

.btn--tertiary:focus:enabled {
  outline: 0.3rem solid var(--clr-secondary-blue-200);
}

.btn--tertiary:active:enabled,
.btn--tertiary.pressed:enabled {
  outline: 0.1rem solid var(--clr-secondary-blue-200);
  background: var(--clr-secondary-blue-50);
  color: var(--clr-secondary-blue-700);
}

.btn--tertiary.isActive:enabled {
  border: 1px solid var(--clr-secondary-blue-500);
  color: var(--clr-secondary-blue-500);
}

.btn--tertiary:disabled {
  color: var(--clr-gray-500);
}

.btn--error:hover:enabled {
  box-shadow: var(--box-shadow-up-hover);
  background: var(--clr-mystic-red-700);
}

.btn--error:focus:enabled {
  outline: 2px solid var(--clr-mystic-red-200);
}

.btn--error:active:enabled,
.btn--error.pressed:enabled {
  background: var(--clr-mystic-red-300);
}

.btn--error:disabled {
  background: var(--clr-gray-200);
  color: var(--clr-gray-500);
}

.btn-new--sm {
  font-size: 1.3rem;
  line-height: normal;
  height: 3.2rem;
  padding: 0 0.8rem;
}

.btn-new--md {
  font-size: 1.4rem;
  line-height: normal;
  height: 4rem;
  padding: 0 0.8rem;
}

.btn-new--lg {
  font-size: 1.6rem;
  line-height: normal;
  height: 4.8rem;
  padding: 0 1.2rem;
}

.btn-new--xl {
  font-size: 2rem;
  line-height: normal;
  height: 5.6rem;
  padding: 0 1.2rem;
}